<template>
  <div class="foot_container">
    <div class="top">
      <div class="top_inner">
        <div class="item_box">
          <div class="item" v-for="item in list" :key="item.name">
            <div class="img">
              <img :src="item.logo" alt="" />
            </div>
            <div>
              <p class="til">{{ item.title }}</p>
              <p v-for="sub in item.children" :key="sub.id" class="c_p" style="line-height: 1.8" @click="handleDetail(sub)">
                {{ sub.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_inner">
        <div class="info_item">
          <div v-for="item in arr" :key="item.id">
            <span>{{ item.title }}：</span>
            <span class="cont" @click="click(item)">{{ item.view }}</span>
          </div>
        </div>
        <p>{{ info.company_ts }}</p>
        <div class="flex_sp">
          <p>{{ info.company_sm }}</p>
          <p>公司地址：{{ info.xsdwdz }}</p>
        </div>
        <div class="flex_sp">
          <p>{{ info.copy_right }}</p>
          <p>
            技术支持：<span style="color: #b2b4c4">{{ info.xsdwmc }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="popup"  v-if=" dialogVisible && imgarr.image" @click="endDialog">
      <div class="po" >
        
        <el-button icon="el-icon-close" circle  @click="endDialog" ></el-button> 
        <el-image :src="imgarr.image"  @load="loadImage"  @click="handleJump"    > </el-image>
        
      </div>
    </div>

  </div>
</template>

<script>
import { loadImage } from "@/utils";
export default {
  data() {
    return {
      list: [],
      arr: [],
      info: {},
      dialogVisible:false,
      imgarr:{},
      loadImage
    };
  },
  created() {
    this.$api("home.getArticle").then((res) => {
      this.list = res.data;
    });
    this.$api("home.getQualifications").then((res) => {
      this.arr = res.data;
    });
    this.$api("home.getFoot").then((res) => {
      this.info = res.data;
    });
  },
  methods: {
    click(item) {
      
      let url = "";
      this.imgarr = {}
      if(item.image){
        this.imgarr = item;
        this.dialogVisible = true
      }else if(item.url){
        url = item.url;
        window.open(url, "_blank");
      }

      // let a = window.open("");
      // a.document.write(`<!DOCTYPE html><html><body ><img src='${url}'/></body></html>`);
    },


    handleJump(){

      if(this.imgarr.url){
        window.open(this.imgarr.url, "_blank");
      }

      this.imgarr = {}
      this.dialogVisible = false

    },

    endDialog(){
      this.imgarr = {}
      this.dialogVisible = false
    },

    handleDetail(item) {
      if (this.$route.path == "/index/news") {
        this.$bus.$emit("updateNews", { id: item.id });
        return;
      }
      this.$router.push({
        name: "News",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.foot_container {
  min-height: 430px;
  .top {
    min-height: 200px;
    background-color: #fff;
    .top_inner {
      width: 1200px;
      margin: 0 auto;
      padding-top: 50px;
      .item_box {
        display: flex;
        justify-content: space-between;
        .item {
          display: flex;
          align-items: flex-start;
          .img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
          }
          .til {
            margin-top: 15px;
            font-size: 20px;
            font-weight: bold;
            color: @themeColor;
            margin-bottom: 15px;
          }
          .name {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            margin-top: 10px;
          }
          .tip1 {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .bottom {
    min-height: 230px;
    background: #2c2f3a;
    padding-top: 39px;
    padding-bottom: 20px;
    box-sizing: border-box;
    color: #fffefe;
    font-size: 13px;
    line-height: 30px;
    .bottom_inner {
      width: 1200px;
      margin: 0 auto;
      .info_item {
        display: flex;
        flex-wrap: wrap;
        .cont {
          margin-right: 15px;
          padding-right: 15px;
          color: #b2b4c4;
          border-right: 1px solid #fffefe;
          cursor: pointer;
        }
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .po {
    position: relative;
    .el-button {
      position: absolute;
      right: -50px;
      top: -50px;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }
  }
}
</style>
