<template>
  <div class="order_wrap">
    <div class="top">
      <span>我的订单</span>
      <i @click="handleClose" class="el-icon-close"></i>
    </div>
    <div class="conten">
      <div class="order_item" v-for="item in list" :key="item.id">
        <div class="item_top flex_sp_c">
          <span>{{ item.addtime }}</span>
          <span>{{ item.status_str }}</span>
        </div>
        <div class="item_cen">
          <div class="img_box">
            <div v-for="sub in item.goods" :key="sub.id">
              <img
                style="width: 43px; height: 43px"
                :src="sub.image.url"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="item_bot flex_sp_c">
          <span class="txt1">￥{{ item.total_price }}</span>
          <!-- <span>胡汉三</span> -->
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="btn" @click="allOreder">查看全部订单</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$api("account.getOrder", { page: 1, page_size: 10 }).then((res) => {
      this.list = res.data;
    });
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    allOreder() {
      if (this.$route.path == "/index/subRouter/order") return;
      this.$router.push("/index/subRouter/order");
      this.handleClose();
    },
  },
};
</script>

<style lang="less" scoped>
.order_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .conten {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    padding: 15px 10px 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      color: transparent;
    }

    .order_item {
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 4px;
      .item_top {
        height: 26px;
        background: #fff7f0;
        padding: 0 10px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
      }
      .item_cen {
        background-color: #fff;
        overflow: hidden;
        border-bottom: 1px solid #eeeeee;
        .img_box {
          display: flex;
          padding: 10px 5px;
          //   overflow-x: scroll;

          //   &::-webkit-scrollbar {
          //     display: none;
          //     width: 0;
          //     height: 0;
          //     color: transparent;
          //   }
          img {
            margin-right: 6px;
          }
        }
      }
      .item_bot {
        height: 30px;
        background-color: #fff;
        padding: 0 10px;
        color: #999;
        .txt1 {
          color: #f93232;
        }
      }
    }
  }
  .bottom {
    padding: 8px;
    padding-top: 12px;
    height: 54px;
    display: flex;
    justify-content: center;
    .btn {
      width: 100px;
      height: 38px;
      background: #f93232;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
