<template>
  <div class="capital_wrap">
    <div class="top">
      <span>我的资产</span>
      <i @click="handleClose" class="el-icon-close"></i>
    </div>
    <div class="conten">
      <div class="item">
        <p>
          账户余额：<span>￥{{ info.money }}</span>
        </p>
        <p>
          积分余额：<span>{{ info.points }}</span>
        </p>
        <p>
          优惠金额：<span>{{ info.coupon }}</span>
        </p>
        <p>
          优惠券：<span>{{ listTotal }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { LgetItem } from "@/utils/storage";

export default {
  data() {
    return { listTotal: 0 };
  },
  computed: {
    info() {
      return LgetItem("info");
    },
  },
  created() {
    this.$api("account.getCoupon", { status: 101 }).then((res) => {
      this.listTotal = res.listTotal;
    });
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style lang="less" scoped>
.capital_wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top {
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    i {
      font-size: 18px;
      cursor: pointer;
    }
  }
  .conten {
    width: 100%;
    flex: 1;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      color: transparent;
    }
    .item {
      background-color: #fff;
      padding: 20px 14px;
      font-weight: 400;
      color: #333333;
      line-height: 1.6;
      span {
        color: #f93232;
      }
    }
  }
}
</style>
