<template>
  <div class="header_top" v-if="show">
    <div class="header_top_inner">
      <div class="logo" @click="gotoIndex">
        <img :src="logo" alt="" />
      </div>
      <div class="cate_wrap">
        <div class="item" v-for="item in cateList" :key="item.url.url" @click="handleCom(item)" :class="[currentPath == item.url.url ? 'active' : '']">
          {{ item.title }}
        </div>
      </div>
      <div class="input_wrap">
        <div class="serach_box">
          <el-input placeholder="请输入搜索内容 名称/厂家" v-model="kw" @keyup.enter.native="handleEnter" @input="handleInput" class="input-w"> </el-input>
          <div class="box" v-if="listShow">
            <div v-for="item in arr" :key="item.value" @click="chooseItem(item)">
              {{ item.title }}
            </div>
          </div>
          <div class="search_str" @click="handleClick">搜索</div>
        </div>
        <el-badge :value="num" class="item">
          <div class="img" @click="$router.push('/index/cart')">
            <img src="@/assets/img/index/cart.png" alt="" />
          </div>
        </el-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/tool.js";
import { handleJump } from "@/utils";
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPath: "",
      cateList: [],
      kw: "",
      handleInput: "",
      arr: [],
      listShow: false,
      logo: "",
    };
  },
  computed: {
    ...mapGetters(["searchKey", "num"]),
  },
  created() {
    
    this.currentPath = this.$route.path;
    this.$api("home.getNavInfo").then((res) => {
      this.logo = res.data.settingImg.wz_logo;
      this.cateList = res.data.menuList;
    });
  },
  mounted() {
    this.handleInput = debounce(this.getkw, 300);
  },
  watch: {
    $route(to) {
      this.currentPath = to.path;
    },
    searchKey(newVal, oldVal) {
      this.kw = newVal.kw;
      console.log(newVal, oldVal, "watch top");
    },
  },
  methods: {
    gotoIndex() {
      if (this.$route.path == "/index/home") return;
      this.$router.push("/index/home");
    },
    getkw() {
      if (!this.kw) return;
      this.$api("home.getSearch", { kw: this.kw }).then((res) => {
        this.arr = res.data;
        this.listShow = true;
      });
    },
    chooseItem(item) {
      this.kw = item.title;
      this.listShow = false;
      this.setKey();
      this.go();
      this.$bus.$emit("updateSearch", { kw: this.kw });
    },
    handleClick() {
      this.listShow = false;
      this.setKey();
      this.go();
      this.$bus.$emit("updateSearch", { kw: this.kw });
    },
    handleEnter() {
      this.listShow = false;
      this.setKey();
      this.go();
      this.$bus.$emit("updateSearch", { kw: this.kw });
    },
    handleCom(item) {
      if (item.url.url === this.currentPath) return;
      handleJump(item.url);
      this.currentPath = item.url.url;
    },
    go() {
      if (this.$route.path == "/index/allGoods") return;
      this.$router.push("/index/allGoods");
    },
    setKey() {
      this.$store.commit("setSearchKey", { kw: this.kw });
    },
  },
};
</script>

<style lang="less" scoped>
.header_top {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 5px 8px 0 rgba(207, 205, 205, 0.28);
  box-sizing: border-box;
  .header_top_inner {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 180px;
      height: 50px;
    }
    .cate_wrap {
      display: flex;
      .item {
        font-size:16px;
        font-weight:600;
        cursor: pointer;
        margin: 0 15px;
        font-size: 15px;
        color: #333333;
        &.active {
          font-weight: bold;
          color: @themeColor;
        }
      }
    }
    .input_wrap {
      display: flex;
      align-items: center;
      .serach_box {
        height: 34px;
        display: flex;
        .input-w {
          width: 210px;
          height: 34px;
          border: 1px solid @themeColor;
          ::v-deep .el-input__inner {
            width: 210px;
            height: 34px;
            border: none;
          }
        }
        .box {
          z-index: 1000;
          position: absolute;
          top: 60px;
          background: #fff;
          width: 190px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          padding: 0 10px;
          max-height: 450px;
          overflow-y: auto;
          line-height: 1.6;
          div {
            cursor: pointer;
            &:hover {
              color: @themeColor;
            }
          }
        }
        .search_str {
          cursor: pointer;
          width: 40px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: @themeColor;
          font-size: 12px;
          color: #ffffff;
          border: 1px solid @themeColor;
        }
      }
      .img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>
